import XLSX from 'xlsx';
import $ from 'jquery';

export const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export function capitalizeWords(str)
{
    return str.replace(/\w\S*/g, function (txt) { return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(); });
}

export function createExcelSheet(data, filename, workSheetName, fields, wscols) {
    var createXLSLFormatObj = [];

    let capitalizedFields = fields.map((key) => {
        return capitalizeWords((key.split("_")).join(" "));
    });

    createXLSLFormatObj.push(capitalizedFields);

    data.forEach((element) => {
        let row = Array(fields.length).fill(" ");
        let data = element.data();

        Object.keys(data).forEach((key) => {
            if (fields.includes(key)) {
                if (key == "timestamp_of_visit" || key == "registered_on" || key == "dob") {
                    let index = fields.indexOf(key);
                    if(data[key] != null){
                        console.log(data[key], key)
                    row[index] = data[key].toDate().toDateString();

                    }
                }
                else {
                    let index = fields.indexOf(key);
                    if(data[key] != null){
                        if(key != "student_id" && key != "college_id"){
                            row[index] = capitalizeWords(data[key]);

                        }
                        else{
                            row[index] = data[key];

                        }
                    }
                }

            }
        })

        createXLSLFormatObj.push(row);
    })

    // $.each(xlsRows, function (index, value) {
    //     var innerRowData = [];
    //     innerRowData.push(index+1)
    //     $.each(value, function (ind, val) {

    //         if(ind != "status"){

    //             if(typeof val === 'string' && !exclude.includes(ind)){
    //                 val = capitalizeFirstLetter(val);
    //             }

    //             innerRowData.push(val);

    //         }
    //     });
    //     createXLSLFormatObj.push(innerRowData);
    // });


    var filename = filename + ".xlsx";

    var ws_name = workSheetName;

    var wb = XLSX.utils.book_new(),
        ws = XLSX.utils.aoa_to_sheet(createXLSLFormatObj);
        
        ws['!cols'] = wscols;

    XLSX.utils.book_append_sheet(wb, ws, ws_name);

    XLSX.writeFile(wb, filename);


}

