import firebase from 'firebase/app';
import 'firebase/firebase-firestore';
import 'firebase/firebase-auth';
import 'firebase/firebase-storage';
import 'firebase/firebase-functions';
import 'firebase/firebase-analytics';

var firebaseConfig = {
    apiKey: "AIzaSyDEHQZXjvPL0I9D0P_iq0ZLF2j6_ReAkG0",
    authDomain: "kerala-colleges.firebaseapp.com",
    databaseURL: "https://kerala-colleges.firebaseio.com",
    projectId: "kerala-colleges",
    storageBucket: "kerala-colleges.appspot.com",
    messagingSenderId: "585788405996",
    appId: "1:585788405996:web:61936e14f35ec025bfcdc9",
    measurementId: "G-GDQXLGGTCC"
  };


// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();


export var db = firebase.firestore();



export var functions = firebase.app().functions('asia-east2');

let isLocalHost = window.location.hostname === "localhost";

if (isLocalHost) {
  console.log("localhost detected!");
  db.settings({
    host: "localhost:8080",
    ssl: false
  });

  functions.useFunctionsEmulator("http://localhost:5001");
}


export var storage = firebase.storage();
export var auth = firebase.auth();
export var serverTimestamp = firebase.firestore.FieldValue.serverTimestamp;
export var fieldValue = firebase.firestore.FieldValue;
export var googleAuthProvider = new firebase.auth.GoogleAuthProvider();

